import {ModalBottom} from "../../components/ModalBottom/ModalBottom";
import "./dieu_khoan.css";

export const DieuKhoanDichVu = ({openDieuKhoan}) => {
  return (
    <ModalBottom
      positionClose={"topRight"}
      colorClose={"white"}
      isOpen={openDieuKhoan}>
      <div className={"box modal-certificate"}>
        <div className={"box-header"}>
          <div className={"backgorund-linear"}>
            Điều khoản Dịch vụ & Chính sách Bảo hành
          </div>
        </div>
        <div className={"content"} id={'chinh_sach_bao_hanh'} style={{padding: '1rem', overflow: 'auto', maxHeight: 'calc(100vh - 100px)'}}>
          <p align="center">
            <strong style={{color: '#2B4063'}}>ĐIỀU KHOẢN DỊCH VỤ VÀ CHÍNH SÁCH BẢO HÀNH</strong>
          </p>
          <p className="mb-1" style={{color: '#0C41A4'}}>
            <strong className="list-index-lv1">A.</strong><strong>ĐIỀU KHOẢN THANH TOÁN</strong>
          </p>
          <p className="mb-1 text-lv1">
            <strong className="list-index-lv1">1.</strong><strong>THANH TOÁN NGAY</strong>
          </p>
          <ul className="thut-lv1">
            <li>Khách hàng thực hiện thanh toán trả trước 100% ngay khi đặt hàng trên App MBBank.</li>
          </ul>
          <p className="mb-1 text-lv1">
            <strong className="list-index-lv1">2.</strong><strong>THANH TOÁN KHI NHẬN HÀNG</strong>
          </p>
          <ul className="thut-lv1">
            <li>Khách hàng thực hiện đặt cọc 10% giá trị đơn hàng ngay khi đặt hàng trên App MBBank.</li>
            <li>Khách hàng thực hiện thanh toán phần còn lại cho người giao hàng để được nhận hàng.</li>
          </ul>
          <p className="mb-1" style={{color: '#0C41A4'}}>
            <strong className="list-index-lv1">B.</strong><strong>CHÍNH SÁCH HỦY</strong><strong>, </strong>
            <strong>ĐỔI TRẢ HÀNG</strong>
          </p>
          <p className="mb-1 text-lv1">
            <strong className="list-index-lv1">1.</strong><strong>CHÍNH SÁCH HỦY HÀNG</strong>
          </p>
          <table border={1} cellSpacing={0} cellPadding={0}>
            <tbody>
            <tr>
              <td width={82} valign="top">
                <p>
                  <strong>Trường hợp</strong>
                </p>
              </td>
              <td width={173} valign="top">
                <p>
                  <strong>
                    Khách hàng tự hủy đơn trên App MBBank trước khi chúng tôi tiếp nhận đơn
                  </strong>
                </p>
              </td>
              <td width={173} valign="top">
                <p>
                  <strong>Khách hàng liên hệ Hotline để hủy đơn trước khi đơn vị vận chuyển đến lấy hàng
                  </strong>
                </p>
              </td>
              <td width={174} valign="top">
                <p>
                  <strong>Khách hàng liên hệ Hotline để hủy đơn sau khi đơn vị vận chuyển đến lấy hàng
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width={82} valign="top">
                <p>
                  <strong>Giá trị hoàn tiền</strong>
                </p>
              </td>
              <td width={173} valign="top">
                <p>
                  100% giá trị khách hàng đã thanh toán. <strong/>
                </p>
              </td>
              <td width={173} valign="top">
                <p>
                  100% giá trị khách hàng đã thanh toán. <strong/>
                </p>
              </td>
              <td width={174} valign="top">
                <p>
                  100% giá trị khách hàng đã thanh toán trừ phí vận chuyển và bảo hiểm.
                </p>
              </td>
            </tr>
            <tr>
              <td width={82} valign="top">
                <p>
                  <strong>Thời gian hoàn tiền</strong>
                </p>
              </td>
              <td width={346} colSpan={2} valign="top">
                <p>
                  Tối đa 05 ngày làm việc kể từ khi hủy đơn hàng.
                </p>
              </td>
              <td width={174} valign="top">
                <p>
                  Tối đa 05 ngày làm việc kể từ khi hàng được hoàn về kho thành công.
                </p>
              </td>
            </tr>
            <tr>
              <td width={82} valign="top">
                <p>
                  <strong>Hình thức hoàn tiền</strong>
                </p>
              </td>
              <td width={519} colSpan={3} valign="top">
                <p>
                  Chúng tôi sẽ chuyển khoản vào tài khoản thanh toán mà khách hàng dùng để thanh toán cho đơn hàng đã đặt.
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          <p>
            <strong className="list-index-lv1">1.2</strong><strong>Phương thức Thanh toán khi nhận hàng:</strong>
          </p>
          <p className="thut-lv1">
            Trong mọi trường hợp, nếu khách hàng hủy đơn hàng trước khi thanh toán toàn bộ giá trị đơn hàng, phần đặt cọc sẽ không được hoàn trả.
          </p>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1 ">2.</strong><strong>CHÍNH SÁCH TRẢ HÀNG</strong>
          </p>
          <p>
            <strong className="list-index-lv1">2.1</strong> <strong>Trả hàng do yêu cầu của khách hàng (không phải do lỗi nhà cung cấp)</strong>
          </p>
          <ul className="thut-lv1">
            <li>Chúng tôi hỗ trợ trả hàng 01 lần duy nhất trong vòng 03 ngày đầu
              tiên kể từ thời điểm khách hàng nhận hàng thành công.
            </li>
            <li>Điều kiện trả hàng, quy trình kiểm tra, thông báo và giải quyết: Như
              quy định ở Điều 4.
            </li>
            <li>Giá trị hoàn tiền: 100% giá trị khách hàng đã thanh toán trừ phí vận
              chuyển và bảo hiểm cho cả 2 chuyến giao hàng và hoàn hàng.
            </li>
          </ul>

          <p>
            <strong className="list-index-lv1">2.2</strong> <strong> Đơn vị vận chuyển không liên lạc được với khách hàng</strong>
          </p>
          <p className="thut-lv1">
            Chúng tôi sẽ tiến hành giao hàng trong 03 ca làm việc (mỗi ca 1 buổi), nếu
            người giao hàng không liên lạc được với khách hàng trong 03 ca này, chúng
            tôi sẽ tiến hành lưu kho vận chuyển trong vòng 03 ngày tiếp theo.
          </p>
          <ul className="thut-lv1">
            <li>
              Trong vòng 03 ngày này, nếu khách hàng liên hệ để giao lại thì phí
              giao lại là 10.000 VNĐ/lần.
            </li>
            <li>
              Sau 03 ngày này, nếu khách hàng không liên hệ để giao lại thì chúng
              tôi sẽ mặc định đơn hàng này bị hủy. Chúng tôi sẽ hoàn tiền chỉ khi nào
              khách hàng chủ động liên hệ Hotline 1900 63 68 19 để yêu cầu hoàn tiền.

            </li>
            <li>
              Điều kiện trả hàng, quy trình kiểm tra, thông báo và giải quyết: Như
              quy định ở Điều 4.
            </li>
            <li>
              Giá trị hoàn tiền:
            </li>
            <table border={1} cellSpacing={0} cellPadding={0}>
              <tbody>
              <tr>
                <td width={82} valign="top">
                  <p>
                    <strong>Thanh toán ngay</strong>
                  </p>
                </td>
                <td width={173} valign="top">
                  <p>
                    <strong>
                      Thanh toán khi nhận hàng
                    </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width={173} valign="top">
                  <p>
                    100% giá trị khách hàng đã thanh toán trừ phí vận chuyển và bảo hiểm cho cả 2 chuyến giao hàng và hoàn hàng.<strong/>
                  </p>
                </td>
                <td width={174} valign="top">
                  <p>
                    Không hoàn lại tiền đặt cọc.
                  </p>
                </td>
              </tr>

              </tbody>
            </table>
          </ul>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">3.</strong><strong>CHÍNH SÁCH ĐỔI HÀNG</strong>
          </p>
          <p>
            <strong className="list-index-lv1">3.1</strong><strong>Đổi hàng do lỗi nhà cung cấp</strong>
          </p>
          <ul className="thut-lv1">
            <li>
              Chúng tôi hỗ trợ đổi hàng 01 lần duy nhất trong vòng 03 ngày đầu
              tiên kể từ thời điểm khách hàng nhận hàng thành công trong các trường hợp
              sau:
            </li>
            <ul>
              <li>Sản phẩm khác mẫu mã, màu sắc so với thông tin đơn đặt hàng</li>
              <li>Sản phẩm bị bể, vỡ, móp, méo, trầy xước</li>
              <li>Thiếu phụ kiện đi kèm (chúng tôi sẽ giao thêm)</li>
            </ul>
            <li>
              Việc đổi hàng được hỗ trợ hoàn toàn miễn phí.
            </li>
          </ul>

          <p>
            <strong className="list-index-lv1">3.2</strong><strong> Đổi hàng do yêu cầu của khách hàng (không phải do lỗi nhà cung cấp)
          </strong>
          </p>
          <ul className="thut-lv1">
            <li>Chúng tôi hỗ trợ đổi hàng 01 lần duy nhất trong vòng 03 ngày đầu
              tiên kể từ thời điểm khách hàng nhận hàng thành công.
            </li>
            <li>Điều kiện trả hàng, quy trình kiểm tra, thông báo và giải quyết: Như
              quy định ở Điều 4.
            </li>
            <li>Giá trị hoàn tiền để khách hàng đặt lại sản phẩm mới: 100% giá trị
              khách hàng đã thanh toán trừ phí vận chuyển và bảo hiểm cho cả 2 chuyến
              giao hàng và hoàn hàng.
            </li>
          </ul>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">4.</strong><strong>QUY TRÌNH ĐỔI TRẢ HÀNG SAU KHI KHÁCH HÀNG ĐÃ NHẬN HÀNG
          </strong>
          </p>
          <p>
            <strong className="list-index-lv1">4.1</strong><strong> Điều kiện đổi trả hàng</strong>
          </p>
          <p className="thut-lv1">
            Khách hàng vui lòng liên hệ Hotline 1900 63 68 19 để được hướng dẫn hỗ trợ
            đổi trả hàng và phải đáp ứng tất cả các điều kiện sau đây:
          </p>
          <ul className="thut-lv1">
            <li>
              Khách hàng quay lại video clip quá trình mở kiện hàng lần đầu tiên
              (video quay liền mạch không đứt đoạn hay cắt ghép thông tin, thể hiện đủ
              các mặt của Hàng hóa vẫn còn được đóng gói cẩn thận, chưa bị bóc hàng hay
              đóng gói lại).
            </li>
            <li>
              Khách hàng quay lại video clip quá trình đóng gói sản phẩm kèm đầy
              đủ hộp, sạc, phụ kiện đi kèm, quà tặng, mút xốp, bóng khí (nếu có),…
            </li>
            <li>
              Sản phẩm phải được giữ nguyên băng keo niêm phong (seal) sản phẩm
              (trừ các trường hợp lỗi do nhà cung cấp).
            </li>
            <li>
              Sản phẩm đổi phải giữ nguyên 100% hình dạng ban đầu (trừ các trường
              hợp lỗi do nhà cung cấp).
            </li>
            <li>
              Thân máy, màn hình sản phẩm không bị trầy xước (trừ các trường hợp
              lỗi do nhà cung cấp).
            </li>
            <li>
              Sản phẩm chỉ dùng cho mục đích sử dụng cá nhân, không áp dụng việc
              sử dụng sản phẩm cho mục đích thương mại.
            </li>
            <li>
              Trả không quá 05 sản phẩm cho cùng 1 khách hàng/ngày.
            </li>

          </ul>
          <p>
            <strong className="list-index-lv1">4.2</strong><strong> Hướng dẫn khách hàng thực hiện thủ tục
          </strong>
          </p>
          <p className="thut-lv1">
            <strong><em>Bước 1: Kiểm tra điều kiện đổi trả hàng</em></strong>
            <br/>
            <span>
                          Khách hàng tự kiểm tra đơn hàng đáp ứng các điều kiện trả hàng như quy
                          định.
                      </span>
          </p>
          <p className="thut-lv1">
            <strong><em>Bước 2: Đăng ký đổi trả</em></strong>
            <br/>
            <span>
                            Khách hàng vui lòng liên hệ Hotline 1900 63 68 19 để cung cấp những thông
                            tin theo yêu cầu để được hỗ trợ đổi trả.
                        </span>
          </p>
          <p className="thut-lv1">
            <strong><em>Bước 3: Đóng gói sản phẩm và gửi hàng</em></strong>
            <br/>
            <span>
                          1. Sản phẩm yêu cầu đổi hoặc trả cần được đóng gói cẩn thận, bên trong bao
                          gồm:
                      </span>
          </p>
          <ul className="thut-lv2">
            <li>
              Sản phẩm muốn đổi hoặc trả
            </li>
            <li>
              Đầy đủ hộp, sạc, phụ kiện đi kèm, quà tặng
            </li>
            <li>
              Mút xốp, bóng khí (nếu có) để đảm bảo hàng nguyên vẹn khi vận chuyển
            </li>
          </ul>
          <p className="thut-lv1">
            2. Bên ngoài kiện hàng, khách hàng cung cấp đầy đủ thông tin như sau:
          </p>
          <ul className="thut-lv2">
            <li>
              Đổi hoặc trả sản phẩm
            </li>
            <li>
              Họ tên người đặt hàng
            </li>
            <li>
              Số điện thoại người đặt hàng
            </li>
            <li>
              Mã đơn hàng
            </li>
            <li>
              Mã vận đơn
            </li>
          </ul>
          <p className="thut-lv1">
            3. Khách hàng cung cấp video quay lại quá trình mở sản phẩm lần đầu tiên
            và video quay lại quá trình đóng gói sản phẩm theo hướng dẫn của nhân viên
            Chăm sóc khách hàng.
          </p>
          <p className="thut-lv1">
            4. Giao kiện hàng cho đơn vị vận chuyển.
          </p>
          <p>
            <strong className="list-index-lv1">4.3</strong><strong> Kiểm tra, thông báo và giải quyết</strong>
            <strong/>
          </p>
          <ul className="thut-lv1">
            <li>Ngay khi nhận được sản phẩm đổi hoặc trả của khách hàng, chúng tôi
              tiến hành đánh giá sản phẩm và thông báo kết quả cùng phương hướng giải
              quyết đến khách hàng trong vòng tối đa 03 ngày làm việc.
            </li>
            <li>Nếu khách hàng đáp ứng đủ các điều kiện nêu trên, chúng tôi sẽ tiến
              hành hoàn tiền cho khách hàng trong vòng tối đa 05 ngày làm việc tiếp theo
              (nếu phát sinh).
            </li>
            <li>
              Nếu sản phẩm được hoàn trả bởi khách hàng không đáp ứng được các
              điều kiện nêu trên, chúng tôi sẽ liên hệ và giao lại sản phẩm đến địa chỉ
              khách hàng cung cấp. Khách hàng sẽ chịu phí vận chuyển và bảo hiểm cho
              chuyến giao này. <strong/>
            </li>
          </ul>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">C.</strong><strong> CHÍNH SÁCH BẢO HÀNH SẢN PHẨM OPPO</strong> <strong/>
          </p>
          <p className="text-lv1">
            <strong className="list-index-lv1">I.</strong><strong> THỜI HẠN BẢO HÀNH</strong>
          </p>
          <p>
            Trong thời hạn 30 ngày đầu tiên tính từ ngày thiết bị được bán ra, nếu
            thiết bị gặp phải những lỗi của nhà sản xuất (Với điều kiện đã loại trừ
            những trường hợp can thiệp về việc thay đổi hệ điều hành của điện thoại khi
            kết nối với máy tính (Root máy), lỗi phụ kiện hoặc các lỗi khác gây ra do
            sử dụng không đúng cách theo bảng hướng dẫn, thẻ nhớ, SIM, ứng dụng bên thứ
            ba, mạng Wifi…), khách hàng được hưởng chính sách miễn phí đổi sản phẩm mới
            (không bao gồm các phụ kiện và vỏ hộp) với điều kiện khách hàng cung cấp
            được chứng từ mua hàng. Thời gian bảo hành đối với máy mới tính từ thời
            gian bắt đầu đổi máy.
          </p>
          <p className="mt-1">
            Trong trường hợp thiết bị phát sinh lỗi, hư hỏng do chất lượng linh kiện
            hay lỗi kỹ thuật trong quy trình sản xuất trong suốt thời gian bảo hành,
            OPPO sẽ cung cấp dịch vụ bảo hành miễn phí cho khách hàng.
          </p>
          <p>
            <strong className="list-index-lv1 mt-2">1.</strong> <strong>Đối với sản phẩm điện thoại thông minh</strong>
          </p>
          <table border={1} cellSpacing={0} cellPadding={0} className={'mt-2'}>
            <tbody>
            <tr>
              <td width={601} colSpan={5}>
                <p align="center">
                  <strong>Trong thời gian bảo hành</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width={120} valign="top">
                <p align="center">
                  <strong>Thân thiết bị</strong> <strong/>
                </p>
              </td>
              <td width={120} valign="top">
                <p align="center">
                  <strong>Pin tích hợp theo thiết bị</strong>
                  <strong>
                  </strong>
                </p>
              </td>
              <td width={120} valign="top">
                <p align="center">
                  <strong>Phụ kiện theo thiết bị</strong>
                </p>
              </td>
              <td width={120} valign="top">
                <p align="center">
                  <strong>Thời gian đổi mới</strong>
                </p>
              </td>
              <td width={120} valign="top">
                <p align="center">
                  <strong>Thời gian thu hồi</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width={120}>
                <p align="center">
                  12 tháng <strong/>
                </p>
              </td>
              <td width={120}>
                <p align="center">
                  12 tháng <strong/>
                </p>
              </td>
              <td width={120}>
                <p align="center">
                  6 tháng <strong/>
                </p>
              </td>
              <td width={120}>
                <p align="center">
                  30 ngày <strong/>
                </p>
              </td>
              <td width={120}>
                <p align="center">
                  / <strong/>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          <table border={1} cellSpacing={0} cellPadding={0} className={'mt-2'}>
            <tbody>
            <tr>
              <td width={601} colSpan={4}>
                <p align="center">
                  <strong>Bảo hành dịch vụ thay thế</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width={150}>
                <p align="center">
                  <strong>Màn hình điện thoại</strong>
                </p>
              </td>
              <td width={150}>
                <p align="center">
                  <strong>Bo mạch chủ</strong>
                </p>
              </td>
              <td width={150}>
                <p align="center">
                  <strong>Pin tích hợp theo thiết bị</strong>
                </p>
              </td>
              <td width={150}>
                <p align="center">
                  <strong>Linh kiện khác</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width={150}>
                <p align="center">
                  6 tháng
                </p>
              </td>
              <td width={150}>
                <p align="center">
                  6 tháng
                </p>
              </td>
              <td width={150}>
                <p align="center">
                  6 tháng
                </p>
              </td>
              <td width={150}>
                <p align="center">
                  3 tháng
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          <p className={'mt-1'}>
            <strong className="list-index-lv1 mt-2">2.</strong><strong>Đối với các sản phẩm khác mang thương hiệu OPPO</strong>
          </p>
          <table border={1} cellSpacing={0} cellPadding={0} className={'mt-2'}>
            <tbody>
            <tr>
              <td width={186}>
                <p align="center">
                  <strong>Đối tượng áp dụng</strong> <strong/>
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  <strong>Thời gian bảo hành</strong> <strong/>
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  <strong>Thời gian đổi mới</strong> <strong/>
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  <strong>Bảo hành dịch vụ thay thế</strong> <strong/>
                </p>
              </td>
            </tr>
            <tr>
              <td width={186}>
                <p align="center">
                  OPPO Watch, OPPO Pad (thân thiết bị)
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  12 tháng
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  30 ngày
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  3 tháng
                </p>
              </td>
            </tr>
            <tr>
              <td width={186}>
                <p align="center">
                  Phụ kiện đầu sạc, Cáp sạc, Tai nghe có dây
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  12 tháng
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  30 ngày
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  /
                </p>
              </td>
            </tr>
            <tr>
              <td width={186}>
                <p align="center">
                  OPPO Band (thân thiết bị)
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  12 tháng
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  30 ngày
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  /
                </p>
              </td>
            </tr>
            <tr>
              <td width={186}>
                <p align="center">
                  Pin sạc dự phòng
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  12 tháng
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  /
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  /
                </p>
              </td>
            </tr>
            <tr>
              <td width={186}>
                <p align="center">
                  Tai nghe Bluetooth
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  12 tháng
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  /
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  3 tháng
                </p>
              </td>
            </tr>
            <tr>
              <td width={186}>
                <p align="center">
                  Loa Bluetooth
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  12 tháng
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  /
                </p>
              </td>
              <td width={139}>
                <p align="center">
                  /
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1 mt-2">II.</strong><strong/>
            <strong>
              CAM KẾT CHẤT LƯỢNG DỊCH VỤ BẢO HÀNH
            </strong>
          </p>
          <p>
            <span className="list-index-lv1">1.</span>Tất cả linh kiện và phụ kiện thay thế là sản phẩm chính hãng hoàn toàn
            mới.
          </p>
          <p>
            <span className="list-index-lv1">2.</span>Phí nhân công sẽ được áp dụng cho các thiết bị sửa chữa thay thế phần
            cứng ngoài điều kiện bảo hành và không được phân phối chính thức tại Việt
            Nam (ví dụ: thiết bị bị hư hỏng bởi các tác nhân ngoại lực vô tình hay cố
            ý; thiết bị hết thời gian bảo hành; thiết bị có dấu hiệu can thiệp phần
            cứng hoặc phần mềm bởi bên thứ ba,…). Nếu có bất kỳ thắc mắc nào, vui lòng
            liên hệ Hotline hoặc Trung tâm bảo hành OPPO gần nhất để được giải đáp.
          </p>
          <p>
            <span className="list-index-lv1">3.</span>Các bộ phận thay thế có thể tận hưởng thời gian bảo hành còn lại ban
            đầu của điện thoại hoặc theo thời gian bảo hành cho các bộ phận thay thế đã
            liệt kê ở trên, tùy theo thời gian nào dài hơn.
          </p>
          <p>
            <span className="list-index-lv1">4.</span>Một số phụ kiện (ví dụ: sách hướng dẫn, phiếu bảo hành, ốp lưng, v.v.)
            không được bảo hành.
          </p>
          <p>
            <span className="list-index-lv1">5.</span>Tất cả những trường hợp đổi mới thiết bị, thay thế linh kiện bảo hành,
            khách hàng phải trả lại sản phẩm hoặc bộ phận bị hỏng (nếu đó không phải là
            toàn bộ sản phẩm) cho Trung tâm bảo hành OPPO hoặc địa điểm dịch vụ bảo
            hành được OPPO chỉ định ngay sau khi đổi hàng hoặc thay thế.
          </p>
          <p>
            <span className="list-index-lv1">6.</span>OPPO có trách nhiệm giải thích tất cả các điều khoản, nội dung trong
            chính sách bảo hành điện thoại OPPO. Quyền giải thích cuối cùng sẽ thuộc về
            OPPO.
          </p>
          <p>
            <span className="list-index-lv1">7.</span>Tất cả các bộ phận của sản phẩm hoặc các thiết bị khác mà OPPO thay
            thế sẽ trở thành tài sản của OPPO.
          </p>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">III.</strong>
            <strong>
              ĐIỀU KIỆN TỪ CHỐI BẢO HÀNH
            </strong>
          </p>
          <p>
            Bảo hành này không bao gồm thiệt hại do con người gây ra hoặc bất kỳ điều
            kiện nào sau đây, tuy nhiên các dịch vụ sửa chữa trả phí luôn được đáp ứng.
          </p>
          <ol className="ol-thut-lv1">
            <li>
              Sản phẩm đã hết thời hạn bảo hành.
            </li>
            <li>
              Hư hỏng do người tiêu dùng gây nên hoặc sử dụng không đúng cách theo
              hướng dẫn sử dụng dẫn dẫn đến hư hỏng hoặc bị sự cố, chẳng hạn như sử dụng
              dưới nhiệt độ quá cao, sử dụng cùng các phụ kiện không phải của OPPO, sử
              dụng các phần mềm can thiệp sâu vào hệ thống vận hành của thiết bị, v.v.
            </li>
            <li>
              Máy đã bị vô nước, hư hỏng, vỡ màn hình, đã qua kiểm tra, sửa chữa,
              thay thế, điều chỉnh, thay đổi phần mềm, phần cứng bởi cá nhân hoặc đơn vị
              khác mà không được OPPO ủy quyền hoặc máy trong tình trạng bị khóa hoặc
              khóa SIM.
            </li>
            <li>
              Máy bị ẩm ướt hoặc ảnh hưởng do các điều kiện môi trường nóng ẩm hoặc
              những thay đổi nhanh chóng trong những điều kiện đó, bị bào mòn, oxy hóa,
              bị thức ăn hoặc chất lỏng đổ vào hoặc bị ảnh hưởng bởi các sản phẩm hóa
              học. Có vết mốc, rỉ sét hoặc bị ăn mòn, oxy hóa bởi hóa chất, chất lỏng.
            </li>
            <li>
              Xảy ra hư hỏng trong một số trường hợp bất khả kháng như: lũ lụt, cháy
              nổ, động đất, sét đánh, tai nạn, côn trùng/động vật xâm nhập, v.v.
            </li>
            <li>
              Không có hóa đơn mua hàng hợp lệ hoặc những giấy tờ hợp lệ khác liên
              quan đến sản phẩm như số IMEI (nếu được yêu cầu), mã vạch bị thay đổi, sửa
              chữa hoặc mất hoặc không đúng với sản phẩm.
            </li>
            <li>
              Tự gây nên tình trạng hư hỏng, biến dạng, nứt, vỡ, mài mòn, trầy xước
              ở thân máy, bàn phím, màn hình, phụ kiện.
            </li>
            <li>
              Các hao mòn tự nhiên theo thời gian sử dụng.
            </li>
          </ol>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">IV.</strong>
            <strong>
              THÔNG TIN QUAN TRỌNG CẦN LƯU Ý
            </strong>
          </p>
          <ol className="ol-thut-lv1">

            <li>
              Khách hàng có trách nhiệm chứng minh sản phẩm là tài sản của mình
              trong các trường hợp có tranh chấp quyền lợi và phục vụ các vấn đề mở khóa,
              bảo mật thông tin và dữ liệu trên thiết bị. Các chứng từ mua hàng và giấy
              tờ tùy thân có thể được Trung tâm bảo hành OPPO yêu cầu cung cấp để đảm bảo
              quyền lợi bảo hành cho khách hàng.
            </li>
            <li>
              Bảo hành có thời hạn không áp dụng với các khiếm khuyết có thể được
              gây ra bởi virus từ việc truy cập bất hợp pháp của bạn hoặc bên thứ ba đến
              các dịch vụ, các tài khoản, hệ thống máy tính hoặc mạng. Việc truy cập trái
              phép có thể xảy ra qua hack, tìm kiếm mật mã hoặc qua các phương tiện khác.
            </li>
            <li>
              Trung tâm bảo hành OPPO khuyến cáo các khách hàng không nên ROOT và Up
              ROM từ những nguồn không rõ ràng hoặc thử nghiệm các bản ROM không do OPPO
              Việt Nam phát hành chính thức. Tất cả các khách hàng ROOT hoặc Up ROM từ
              bên thứ ba cung cấp mà không phải của OPPO Việt Nam phát hành dẫn đến xảy
              ra lỗi và ảnh hường phần cứng của máy sẽ bị mất quyền bảo hành. Kiến nghị
              quý khách hàng nên sử dụng các bản ROM do chính OPPO Việt Nam phát hành để
              được đảm bảo quyền lợi bảo hành tốt nhất.
            </li>
            <li>
              Trước khi mang máy đến bảo hành hoặc gửi thông qua các đơn vị khác
              (nhà vận chuyển, cửa hàng điện thoại,…), quý khách hãy tự lưu lại danh bạ
              và các dữ liệu quan trọng và sau đó xóa chúng nếu muốn. Để tôn trọng quyền
              riêng tư của khách hàng, Trung tâm dịch vụ khách hàng của OPPO và nhân viên
              của Trung tâm sẽ không chịu trách nhiệm sao lưu hoặc khôi phục dữ liệu,
              cũng như không chịu trách nhiệm về bất kỳ hậu quả nào do mất hoặc lộ dữ
              liệu.
            </li>
            <li>
              Khi đến nhận máy, yêu cầu quý khách hàng mang theo phiếu bảo hành sửa
              chữa của OPPO, nếu phiếu bảo hành bị thất lạc quý khách hàng cần mang giấy
              tờ khác chứng minh đã mang máy đến Trung tâm bảo hành OPPO.
            </li>
            <li>
              OPPO sẽ không có trách nhiệm bảo hành vùng phủ sóng, các dịch vụ và
              loại mạng di động hoặc hệ thống hoặc mạng của các nhà cung cấp mạng di động
              và các lỗi do hệ thống mạng của các nhà cung cấp mạng di động.
            </li>
            <li>
              Sản phẩm của bạn là một thiết bị điện tử phức tạp. OPPO khuyến khích
              bạn hãy đọc kỹ hướng dẫn sử dụng và những hướng dẫn được cung cấp cho Sản
              phẩm. Xin lưu ý rằng Sản phẩm có thể có màn hình có độ chính xác cao, ống
              kính máy ảnh và các bộ phận khác có thể bị trầy xước hoặc bị hư hỏng nếu
              không cẩn thận.
            </li>
            <li>
              Tất cả các thông tin về bảo hành, tính năng và đặc điểm của sản phẩm
              có thể thay đổi mà không cần thông báo trước.
            </li>
            <li>
              Các sản phẩm không phải điện thoại và không có sự quản lý bằng IMEI
              hoặc SN, bắt buộc phải có chứng từ mua hàng để xác định thời gian bảo hành.
            </li>
            <li>
              Theo luật cho phép, OPPO sẽ không bảo đảm rằng bất cứ phần mềm nào của
              OPPO cũng đáp ứng yêu cầu của bạn sẽ hoạt động với bất cứ các ứng dụng phần
              cứng hoặc phần mềm được cung cấp bởi bên thứ ba.
            </li>
          </ol>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">V.</strong> <strong>THÔNG TIN KHÁC</strong>
          </p>
          <ol className="ol-thut-lv1">

            <li>
              Trong những trường hợp thiết bị sử dụng trong điều kiện ẩm ướt, hư
              hỏng do nước hoặc tự sửa chữa…, các sự cố khác có thể phát sinh xảy ra sau
              khi thiết bị đã được Trung tâm dịch vụ khách hàng xử lý, điều này được coi
              là một vấn đề gây ra bởi lỗi chính nó và không thể được sửa chữa. Trung tâm
              dịch vụ được ủy quyền có thể yêu cầu bạn ký một thỏa thuận sửa chữa riêng
              trước khi sửa chữa theo hoàn cảnh như vậy.
            </li>
            <li>
              Nếu chính sách bảo hành xung đột với luật và quy định quốc gia, tất
              cả các luật và quy định của quốc gia sẽ được ưu tiên.
            </li>
          </ol>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">VI.</strong> <strong>BẢNG MÔ TẢ LỖI</strong>
          </p>
          <table border={1} cellSpacing={0} cellPadding={0} className={'mt-2'}>
            <tbody>
            <tr>
              <td width={84}>
                <p>
                  <strong>Thành phần</strong> <strong/>
                </p>
              </td>
              <td width={507}>
                <p align="center">
                  <strong>Mô tả lỗi</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width={84} rowSpan={11}>
                <p>
                  Thân máy
                </p>
              </td>
              <td width={507}>
                <p>
                  Ứng dụng không sử dụng được như giấy hướng dẫn sử dụng.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Không thể mở hoặc tắt nguồn.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Không gửi tin nhắn được.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Màn hình không hiển thị.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Chế độ rung không thực hiện được.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Xảy ra lỗi khi thực hiện cuộc gọi.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Không nhận được SIM.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Không thể thiết lập nhạc chuông/tắt âm, lỗi âm thanh.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Máy bị lỗi do kết cấu do quá trình sản xuất.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Cổng USB không kết nối bình thường được.
                </p>
              </td>
            </tr>
            <tr>
              <td width={507}>
                <p>
                  Màn hình xuất hiện 4 điểm chết trở lên.
                </p>
              </td>
            </tr>
            <tr>
              <td width={84}>
                <p>
                  Bộ sạc
                </p>
              </td>
              <td width={507}>
                <p>
                  Không thế sạc pin bình thường.
                </p>
              </td>
            </tr>
            <tr>
              <td width={84}>
                <p>
                  Pin
                </p>
              </td>
              <td width={507}>
                <p>
                  Sau khi sạc pin, điện thoại không thể sử dụng bình thường
                  (lượng pin còn hơn 80%).
                </p>
              </td>
            </tr>
            <tr>
              <td width={84}>
                <p>
                  Tai nghe
                </p>
              </td>
              <td width={507}>
                <p>
                  Không thể thực hiện cuộc gọi bằng tai nghe.
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          <p className={'mt-2 text-lv1'}>
            <strong className="list-index-lv1">VII.</strong>
            <strong>HOTLINE TRUNG TÂM BẢO HÀNH OPPO</strong>
          </p>
          <p>
            Hotline Trung tâm bảo hành OPPO chủ yếu dùng để hỗ trợ, giải quyết nhanh
            chóng mọi thắc mắc từ phía khách hàng.
          </p>
          <p>
            Hotline: 1800 57 77 76
          </p>
          <p>
            Email: <a href="mailto:support.vn@oppo.com"><u>support.vn@oppo.com</u></a>
          </p>
          <p>
            Website: <a href="https://www.oppo.com/vn/"><u>https://www.oppo.com/vn/</u></a>
          </p>
          <p>
            Địa chỉ TTBH toàn quốc:<strong/> <a href="https://support.oppo.com/vn/service-center/"><u>https://support.oppo.com/vn/service-center/</u></a>
            <strong/>
          </p>
        </div>
      </div>
    </ModalBottom>
  )
}