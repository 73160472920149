import "./style.css";
import arrowDown from "./../../assets/images/arrowDownColor.png";
import close from "./../../assets/images/close.png";
import { TopBar } from "../../components/TopBar/TopBar";
import { BottomPurchase } from "../../components/BottomPurchase/BottomPurchase";
import { useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { Textarea } from "../../components/ui/Textarea";
import Select from "react-select";
import { UserAuth } from "../../context/AuthContext";
import { CallApiBackend } from "../../utils/CallApiBackend";
import * as Msg from "./../../components/ui/Toastify/Toastify";
import { formatPrice } from "../../utils/formatPrice";
import { DelayToastTime } from "../../constants/TimeConstant";
import {
  ChiTietDonHang,
  GetAddress,
  LayDiaChiCap4,
  SuaDonHang,
} from "../../constants/ApiEndPoint";
import { ValidatePhone } from "../../utils/Validations";

export function EditOrderScreen() {
  const { order_id } = useParams();
  const { userData } = useContext(UserAuth);
  const hasHamletProvinces = ["68", "91"];

  const [optionAddress, setOptionAddress] = useState({
    province: [],
    district: [],
    ward: [],
    hamlet: [],
  });
  const [location, setLocation] = useState({});
  const [orderInfo, setOrderInfo] = useState(null);

  const getClassifyString = (classifiesArr) => {
    return classifiesArr.map((e) => e.name + ": " + e.value).join(", ");
  };

  const getLv4Address = async (location) => {
    const result = await CallApiBackend(location, LayDiaChiCap4, "POST");
    if (result.data.status === 1) {
      return result.data.data;
    } else {
      return [];
    }
  };

  // gọi api lấy địa chỉ, type = 1,2,3 - tỉnh - quận - xã
  const CallApiOptionAddress = useCallback(async (input, typeAddress) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
    };
    const result = await CallApiBackend(input, GetAddress, "GET");
    if (result.data.status === 1) {
      const convert = ConvertOptions(result.data.data, typeAddress);
      setOptionAddress((item) => ({
        ...item,
        [keyAddress[typeAddress]]: convert,
      }));
      return convert;
    }
    return [];
  }, []);

  // get chi tiet đơn hàng
  useEffect(() => {
    var full_address;
    CallApiBackend({ order_id: order_id }, ChiTietDonHang, "GET")
      .then((result) => {
        if (result.data.status === 1) {
          setOrderInfo(result.data.data);
          full_address = result.data.data.full_address;
          for (const key in full_address) {
            if (key === "province" || key === "district" || key === "ward") {
              full_address[key].label = full_address[key].name;
              full_address[key].value = full_address[key].code;
            }
            if(key==="hamlet"){
              let hamlet = {
                label: full_address[key],
                value: full_address[key],
                name :full_address[key],
                code :full_address[key]
              }
              full_address[key] = hamlet

            }
          }
          setLocation({
            ...full_address,
            fullname: result.data.data.fullname,
            phone: result.data.data.phone,
            note: result.data.data.note,
          });
          return CallApiOptionAddress({ type: 1 }, 1);
        }
      })
      .then((result1) => {
        if (result1) {
          setOptionAddress((prev) => ({
            ...prev,
            province: result1,
          }));
        }
        return CallApiOptionAddress(
          { type: 2, parent_code: full_address.province.code },
          2
        );
      })
      .then((result2) => {
        if (result2) {
          setOptionAddress((prev) => ({
            ...prev,
            district: result2,
          }));
        }
        return CallApiOptionAddress(
          { type: 3, parent_code: full_address.district.code },
          3
        );
      })
      .then((result3) => {
        if (result3) {
          setOptionAddress((prev) => ({
            ...prev,
            ward: result3,
          }));
          if (hasHamletProvinces.includes(full_address.province.code)) {
            getLv4Address({
              location: {
                province: full_address.province.name,
                district: full_address.district.name,
                ward: full_address?.ward?.name,
              },
            }).then((hamlet) => {
              let hamletCoverted = hamlet.map((e) => ({
                value: e,
                code: e,
                label: e,
                name: e,
                type: 4,
              }));
              setOptionAddress((item) => ({ ...item, hamlet: hamletCoverted }));
            });
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  const ConvertOptions = (list, type) =>
    list.map((item) => ({
      ...item,
      ...{ value: item.code, label: item.name, type: type },
    }));

  const onChangeLocation = async (value) => {
    var list_addrr;
    if (value.type !== 3) {
      if (value.type === 4) {
        setLocation((item) => ({ ...item, hamlet: value }));
      } else {
        await CallApiOptionAddress(
          { type: value.type + 1, parent_code: value.code },
          value.type + 1
        ).then((res) => {
          list_addrr = res;
          if (value.type === 1) {
            setLocation((item) => ({
              ...item,
              province: value,
              district: null,
              ward: null,
              hamlet: null,
            }));
            setOptionAddress((item) => ({
              ...item,
              district: [...list_addrr],
              ward: [],
              hamlet: [],
            }));
          } else if (value.type === 2) {
            setLocation((item) => ({
              ...item,
              district: value,
              ward: null,
              hamlet: null,
            }));
            setOptionAddress((item) => ({
              ...item,
              ward: [...list_addrr],
              hamlet: [],
            }));
          }
        });
      }
    } else {
      setLocation((item) => ({ ...item, ward: value, hamlet: null }));
      if (hasHamletProvinces.includes(location.province.code)) {
        await getLv4Address({
          location: {
            province: location.province.name,
            district: location.district.name,
            ward: value.name,
          },
        }).then((hamlet) => {
          let hamletCoverted = hamlet.map((e) => ({
            value: e,
            code: e,
            label: e,
            name: e,
            type: 4,
          }));
          setOptionAddress((item) => ({ ...item, hamlet: hamletCoverted }));
        });
      }
    }
  };
  const onChangeInput = (element) => {
    if (element.target.name === "phone") {
      const input = element.target;
      input.value = input.value.replace(/\D/g, "");
    }
    setLocation((item) => ({
      ...item,
      [element.target.name]: element.target.value,
    }));
  };
  const onClickEdit = () => {
    if (
      !location.province ||
      !location.district ||
      !location.ward ||
      !location.address ||
      (!location.hamlet &&
        hasHamletProvinces.includes(location?.province?.code))
    ) {
      Msg.notifyError(
        "Vui lòng điền đầy đủ thông tin giao hàng!",
        DelayToastTime
      );
    } else if (!location.fullname || !location.phone) {
      Msg.notifyError(
        "Vui lòng điền đầy đủ thông tin người nhận!",
        DelayToastTime
      );
    } else if (ValidatePhone(location.phone).status === false) {
      Msg.notifyError(
        "Vui lòng điền đầy số điện thoại chính xác!",
        DelayToastTime
      );
    } else {
      // const input = {
      //   location: {
      //     province: location.province.label,
      //     district: location.district.label,
      //     ward: location.ward.label,
      //     address: location.address,
      //     note: location.note,
      //   },
      //   product_id: orderInfo.productInfo.product_id,
      //   qty: orderInfo.count,
      //   classify: orderInfo.product.classify_items,
      // };
      // window.location.href = "/payment/result/sasdasd";
      // CallApiBackend(input, "/order/buy-now1", "POST").then((result) => {
      //   if (result.data.status === 1) {
      //     window.postMessage(
      //       JSON.stringify({
      //         type: "PAYMENT_HUB_TRANSACTION",
      //         data: {
      //           merchant: {
      //             code: "MBAL",
      //             name: "Bảo hiểm nhân thọ MB AGEAS LIFE",
      //           },
      //           type: {
      //             code: "BHUT",
      //             name: "Mua bảo hiểm ung thư",
      //             allowCard: true,
      //           },
      //           id: "AJX014TUYI1121",
      //           amount: 1000000,
      //           description: "Mua bao hiem ung thu MBAL 615000",
      //           successMessage:
      //             "Cám ơn bạn đã mua bảo hiểm. MBAL sẽ liên lạc lại với bạn trong vòng 24h",
      //         },
      //       })
      //     );
      //
      //   }
      // });

      const thongTinDonHang = {
        order_id: order_id,

        location: {
          province: {
            code: location.province.code,
            name: location.province.name,
          },
          district: {
            code: location.district.code,
            name: location.district.name,
          },

          ward: {
            code: location.ward.code,
            name: location.ward.name,
          },
          address: location.address,
          hamlet: location.hamlet?.name 
        },
        // cif: userData.cif,
        fullname: location.fullname,
        phone: location.phone,
        note: location.note,
        display_price : orderInfo.payment
      };
      CallApiBackend(thongTinDonHang, SuaDonHang, "POST").then((result) => {
        if (result.data.status === 1) {
          Msg.notifyInfo("Chỉnh sửa đơn hàng thành công", DelayToastTime);
          setTimeout(() => {
            window.location.href = "/history/detail/" + order_id;
          }, DelayToastTime);
        }
      });
    }
  };
  const removeValueInput = (name) => {
    setLocation((item) => ({ ...item, [name]: "" }));
  };

  return (
    orderInfo && (
      <>
        <TopBar goHome={false} title={"THANH TOÁN"} />
        <div style={{ marginBottom: "15px" }}>
          <main className={"main-purchase"}>
            <section className={"box-purchase"}>
              <h3 className={"title"}>Thông tin giỏ hàng</h3>
              <div className={"box-content"}>
                {orderInfo.order_details.map((orderInfoItem, key) => (
                  <div
                    key={key}
                    className={"product-detail"}
                    style={{ paddingBottom: "10px", marginBottom: "13px" }}
                  >
                    <div
                      className={
                        "d-flex justify-content-start align-items-start"
                      }
                      style={{ marginBottom: "14px" }}
                    >
                      <div className={"thumb"} style={{ flex: 1 }}>
                        <img
                          style={{ objectFit: "contain" }}
                          width={70}
                          height={70}
                          src={orderInfoItem && orderInfoItem.icon}
                          alt=""
                        />
                      </div>
                      <div className={"name"} style={{ flex: 4 }}>
                        <p className={"product-name"}>
                          {orderInfoItem.product_name}
                        </p>

                        <span className={"classify"}>
                          <p
                            style={{
                              borderRadius: "0.125rem",
                              background: "#D9D9D9",
                              padding: "0 10px",
                              display: "inline",
                              border: "",
                            }}
                          >
                            {"Phân loại: "}{" "}
                            {getClassifyString(orderInfoItem.classify)}
                          </p>
                        </span>
                        <div className={"purchase-price"}></div>
                      </div>
                    </div>
                    <div className={"count-items"}>
                      <p>x{orderInfoItem.qty}</p>
                    </div>
                    <div
                      className={"price style-price-order"}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <del className={"cost"}>
                        {formatPrice(orderInfoItem.listed_price, ".")}đ
                      </del>
                      <span
                        style={{
                          color: "#FF0000",
                          fontSize: "1.25rem",
                          fontWeight: "700",
                          marginLeft: "7px",
                        }}
                      >
                        {formatPrice(orderInfoItem.sale_price, ".")}đ
                      </span>
                    </div>
                    <span className={"index-order-item"}>{key + 1}</span>
                  </div>
                ))}

                {/* <div className={"payment-details"}>
                  <div className={"detail-item"}>
                    <p className={"key"}>Tổng tiền hàng</p>
                    <p className={"value"}>
                      {formatPrice(orderInfo.tongTienHang)} VND
                    </p>
                  </div>
                  <div className={"detail-item"}>
                    <p className={"key"}>Phí giao hàng</p>
                    <p className={"value"}>
                      {formatPrice(thanhToanInfo.tongTienVanChuyen)} VND
                    </p>
                  </div>
                  <div className={"detail-item"}>
                    <p className={"key"}>Miễn phí giao hàng</p>
                    <del className={"value"}>
                      {formatPrice(thanhToanInfo.giamGiaVanChuyen)} VND
                    </del>
                  </div>
                  <div className={"detail-item"}>
                    <p className={"key"}>Tổng tiền thanh toán</p>
                    <p className={"value"}>
                      {formatPrice(
                        thanhToanInfo.tongTienHang +
                          thanhToanInfo.tongTienVanChuyen -
                          thanhToanInfo.giamGiaVanChuyen
                      )}{" "}
                      VND
                    </p>
                  </div>
                </div> */}
              </div>
            </section>
            <section className={"box-purchase"}>
              <h3 className={"title"}>Thông tin thanh toán</h3>
              <div
                className={"box-content border-box"}
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <div className={"user-name"}>
                    <span>Tài khoản thanh toán:&nbsp;</span>
                    <span style={{ textTransform: "uppercase" }}>
                      {userData?.fullname}
                    </span>
                  </div>
                  <div className={"price"}>
                    <p>
                      {" 222 " ?? formatPrice(userData?.payment?.total)} VND
                    </p>
                  </div>
                </div>
                <div className={"open-detail"}>
                  <img src={arrowDown} width={12} alt="" />
                </div>
              </div>
            </section>
            <section className={"box-purchase"}>
              <h3 className={"title"}>Thông tin người nhận</h3>
              <div className={"box-content border-box"}>
                <div className={"form-group"}>
                  <label htmlFor="fullname">Tên người nhận</label>
                  <Textarea
                    rows={1}
                    name={"fullname"}
                    id={"fullname"}
                    onChange={onChangeInput}
                    value={location?.fullname ?? ""}
                    placeholder={"Nhập họ tên người nhận"}
                  />
                </div>
                <div
                  className={"close-input"}
                  onClick={() => removeValueInput("fullname")}
                >
                  <img src={close} width={22} alt="" />
                </div>
              </div>
              <div className={"box-content border-box"}>
                <div className={"form-group"}>
                  <label htmlFor="phone">Số điện thoại</label>
                  <input
                    type={"text"}
                    name={"phone"}
                    onChange={onChangeInput}
                    id={"phone"}
                    placeholder={"Nhập số điện thoại người nhận"}
                    value={location?.phone ?? ""}
                  />
                </div>
                <div
                  className={"close-input"}
                  onClick={() => removeValueInput("phone")}
                >
                  <img src={close} width={22} alt="" />
                </div>
              </div>
              <p style={{ margin: "5px", color: "red" }}>
                {ValidatePhone(location.phone).msg}{" "}
              </p>
            </section>
            <section className={"box-purchase"}>
              <h3 className={"title"}>Thông tin giao hàng</h3>
              <div className={"box-content border-box"}>
                <div className={"form-group w-100"}>
                  <label htmlFor="province">Tỉnh/Thành phố</label>
                  <Select
                    placeholder={"Chọn tỉnh/thành phố"}
                    options={optionAddress.province}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "none",
                        boxShadow: "none",
                        width: "100%",
                        paddingRight: "10px",
                        minHeight: "27px",
                        height: "27px",
                        marginTop: "2px",
                      }),
                      valueContainer: (base) => ({ ...base, padding: "0" }),
                    }}
                    onChange={onChangeLocation}
                    value={location?.province}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </div>
                <div className={"close-input map-pin"}>
                  <img src={arrowDown} width={12} alt="" />
                </div>
              </div>
              <div className={"box-content border-box"}>
                <div className={"form-group w-100"}>
                  <label htmlFor="district">Quận/Huyện</label>
                  <Select
                    placeholder={"Chọn quận/huyện"}
                    options={optionAddress?.district}
                    // isDisabled={optionAddress.district.length === 0}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "none",
                        boxShadow: "none",
                        width: "100%",
                        paddingRight: "10px",
                        minHeight: "27px",
                        height: "27px",
                        marginTop: "2px",
                      }),
                      valueContainer: (base) => ({ ...base, padding: "0" }),
                    }}
                    value={location?.district}
                    onChange={onChangeLocation}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </div>
                <div className={"close-input map-pin"}>
                  <img src={arrowDown} width={12} alt="" />
                </div>
              </div>
              <div className={"box-content border-box"}>
                <div className={"form-group w-100"}>
                  <label htmlFor="ward">Phường/Xã</label>
                  <Select
                    placeholder={"Chọn phường/xã"}
                    options={optionAddress?.ward}
                    value={location?.ward}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: "none",
                        boxShadow: "none",
                        width: "100%",
                        paddingRight: "10px",
                        minHeight: "27px",
                        height: "27px",
                        marginTop: "2px",
                        backgroundColor: "",
                      }),
                      valueContainer: (base) => ({ ...base, padding: "0" }),
                      input: (base) => ({
                        ...base,
                        backgroundColor: "",
                      }),
                    }}
                    isSearchable={false}
                    onChange={onChangeLocation}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </div>
                <div className={"close-input map-pin"}>
                  <img src={arrowDown} width={12} alt="" />
                </div>
              </div>
              {hasHamletProvinces.includes(location?.province?.code) && (
                <div className={"box-content border-box"}>
                  <div className={"form-group w-100"}>
                    <label htmlFor="hamlet">Tên thôn/ấp/xóm/tổ</label>
                    <Select
                      placeholder={"Chọn thôn/ấp/xóm/tổ"}
                      options={optionAddress.hamlet}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: "none",
                          boxShadow: "none",
                          width: "100%",
                          paddingRight: "10px",
                          minHeight: "27px",
                          height: "27px",
                          marginTop: "2px",
                          backgroundColor: "",
                        }),
                        valueContainer: (base) => ({ ...base, padding: "0" }),
                        input: (base) => ({
                          ...base,
                          backgroundColor: "",
                        }),
                      }}
                      isSearchable={false}
                      value={location?.hamlet}
                      onChange={onChangeLocation}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                    />
                  </div>
                  <div className={"close-input map-pin"}>
                    <img src={arrowDown} width={12} alt="" />
                  </div>
                </div>
              )}
              <div className={"box-content border-box"}>
                <div className={"form-group"}>
                  <label htmlFor="address">Đường, Số nhà, toàn nhà</label>
                  <Textarea
                    rows={1}
                    name={"address"}
                    id={"address"}
                    onChange={onChangeInput}
                    defaultValue={location?.address}
                    placeholder={"Nhập địa chỉ "}
                  />
                </div>
              </div>
              <div className={"box-content border-box"}>
                <div className={"form-group"}>
                  <label htmlFor="note">Ghi chú</label>
                  <Textarea
                    rows={1}
                    name={"note"}
                    id={"note"}
                    onChange={onChangeInput}
                    placeholder={"Thêm ghi chú"}
                    defaultValue={location?.note}
                  />
                </div>
              </div>
            </section>
          </main>
        </div>
        <BottomPurchase paddingX={15} paddingY={20}>
          <div className={"d-flex justify-content-between"}>
            <div className={"total-money"} style={{ margin: "auto 0" }}></div>
            <div
              onClick={onClickEdit}
              className={"buy-now"}
              style={{ width: "100%" }}
            >
              <button
                className="fail-button"
                id="fail-button-1"
                style={{ marginTop: "5px", width: '100%' }}
              >
                THAY ĐỔI THÔNG TIN
              </button>
            </div>
          </div>
        </BottomPurchase>
      </>
    )
  );
}
