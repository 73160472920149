import React from "react";
import { useState } from "react";
import contact from "../../assets/images/contact.png";
import contact_phone from "../../assets/images/contact_phone.png";
import contact_zalo from "../../assets/images/contact_zalo.png";
import "./Contact.css";
import AnimateHeight from "react-animate-height";
import { Draggable } from "../Draggable/Draggable";

const Contact = () => {
  const [openContactOptions, setOpenContactOptions] = useState(false);
  return (
      <div style={{ ...styles.container }}>
      <div style={{ ...styles.wrapper, top: "45vh" }}>
        <div
          className={"background-contact"}
          onClick={() => setOpenContactOptions(!openContactOptions)}
          style={{ display: openContactOptions ? "block" : "none" }}
          />
        <div style={{ zIndex: 100 }}>
          <Draggable
           style={{...styles.draggable, width: '44px'}} >
            <div
              className="contact-icon-wrapper"
              onClick={() => setOpenContactOptions(!openContactOptions)}
            >
              <div
                className="button-contact phone"
                style={{ marginBottom: "25px" }}
              >
                <div className="phone-vr-circle-fill"></div>
                <div className="phone-vr">
                  <img
                    className="contact-icon"
                    style={{ ...styles.icon, marginBottom: 0 }}
                    src={contact}
                    alt="contact"
                  />
                </div>
              </div>
            </div>
            <AnimateHeight
              duration={500}
              height={openContactOptions ? "auto" : 0}
            >
              <div className="contact-icon-wrapper">
                <a href="tel:1900636819"
                   target="_parent" rel="noreferrer">
                  <img
                    className="contact-icon"
                    style={{ ...styles.icon }}
                    src={contact_phone}
                    alt="contact_phone"
                  />
                </a>
              </div>
              <div className="contact-icon-wrapper">
                <a
                  href="https://zalo.me/4508944984917366622"
                  target="_parent"
                  rel="noreferrer"
                >
                  <img
                    className="contact-icon"
                    style={{ ...styles.icon, marginBottom: 0 }}
                    src={contact_zalo}
                    alt="contact_zalo"
                  />
                </a>
              </div>
            </AnimateHeight>
          </Draggable>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    // height: "100vh",
    // width: "100vw",
    position: "fixed",
    top: "calc(100vh - 44px * 3 - 175px)",
    right: "0",
    zIndex: "20",
    // transform: 'translateY(-50%)'
  },
  draggable: {
    position: "fixed",
    top: "calc(100vh - 44px * 3 - 175px)",
    right: "10px",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    // zIndex: "10",
    right: "0",
    top: "50vh",
    marginRight: "5px",
  },
  icon: {
    marginBottom: "25px",
    width: "44px",
    height: "44px",
    borderRadius: "50%",
  },
};

export default Contact;
